<template>
  <!--  <div class="login-container">-->
  <LoginContainer>
    <el-form
        @submit.native.prevent="checkLogin"
        class="login-form"
    >
      <div class="login-container--header">
        {{ $t('system.login') }}
        <lang-select
            @changeLanguage="changeLanguage"
            class="login-lang-select"
        ></lang-select>
      </div>
      <div class="title-container">
        <img
            :src="logoImage"
            class="center-block logo"
            v-if="logoImage"
        >
        <h3
            class="title"
            v-else
        >{{ $t('login.title') }}</h3>
      </div>
      <el-form-item required>
        <el-input
            :class="checkinputRequired('email') ? 'validate-error' : ''"
            :placeholder="$t('system.email')"
            name="email"
            v-model="email"
        >
          <i slot="prefix">
            <font-awesome-icon icon="user" />
          </i>
        </el-input>
        <div
            class="el-form-item__error"
            v-if="backend_errors.email && !checkinputRequired('email')"
        >
          {{ validationTranslate(backend_errors.email[0]) }}
        </div>
      </el-form-item>
      <el-form-item required>
        <el-input
            :class="checkinputRequired('password') ? 'validate-error' : ''"
            :placeholder="$t('system.password')"
            autocomplete="off"
            name="password"
            type="password"
            v-model="password"
        >
          <i slot="prefix">
            <font-awesome-icon icon="unlock" />
          </i>
        </el-input>
        <div
            class="el-form-item__error"
            v-if="backend_errors.password && !checkinputRequired('password')"
        >
          {{ $t(backend_errors.password[0]) }}
        </div>
      </el-form-item>
      <div class="d-flex justify-content-between align-items-center">
        <router-link to="forgot-password">{{ $t('login.forgot-password') }}</router-link>
        <el-button
            :loading="loading"
            class="auth--buttonPrimary"
            native-type="submit"
            type="primary"
        >
          {{ $t('system.login') }}
        </el-button>
      </div>
    </el-form>
  </LoginContainer>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';
import LoginContainer from './LoginContainer.vue';

export default {
  name: 'Login',
  components: {
    LoginContainer,
    LangSelect: () => import('../../components/LangSelect/index.vue'),
  },
  data() {
    return {
      email: '',
      password: '',
      backend_errors: {},
      inputRequiredEmpty: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['entryUrl']),
    ...mapActions('workOrders', ['clearTabs']),
    logoImage() {
      return this.$store.getters.logo;
    },
  },
  methods: {
    async checkLogin() {
      this.checkValidation().then(async () => {
        this.loading = true;
        axios.post('token', {
          username: this.email,
          password: this.password,
          grant_type: 'password',
          client_id: Number(process.env.VUE_APP_CLIENT_ID),
          client_secret: process.env.VUE_APP_CLIENT_SECRET,
          scope: '',
        }, {
          type: 'auth',
        }).then(async response => {
          const data = response.data;
          await this.$store.dispatch('auth/login', {
            token: data.access_token,
            refreshToken: data.refresh_token,
          });
          // if (data.authenticated) {
          //   let token = data.access_token;
          // this.$store.commit('auth/SET_USER', {});
          // this.$store.commit('auth/SET_TOKEN', token);
          // this.$store.commit('auth/SET_CLIENT_ID', data.clientId);
          // this.$store.commit('auth/LOGIN_USER');
          // this.$store.commit('auth/saveAuthInLocalStorage', {
          //   token: token
          // });
          // console.log('data.permissions', data.permissions)
          //   if (data.permissions) {
          //     this.$store.commit('permission/SET_PERMISSIONS', data)
          //   }
          //   let lang = window.localStorage.getItem('langLocal') || window.localStorage.getItem('langGlobal') || 'en'
          // this.changeLanguageMixin(lang)
          await this.$router.push(this.entryUrl);
          this.loading = false;
          this.clearTabs();
          // }
        }).catch(() => {
          this.loading = false;
          this.backend_errors.password = ['system.invalid_credentials'];
        })
            .finally(() => {
              this.loading = false;
            });
      });
    },
    changeLanguage(lang) {
      window.localStorage.setItem('langGlobal', lang);
      this.changeLanguageMixin(lang).then(() => {
        document.title = this.$t('system.login');
      });
    },
  },
};
</script>
