<template>
  <div class="login-container">
    <slot />
  </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'LoginContainer',
  created() {
    window.removeEventListener('focus', () => {
      this.$store.dispatch('permission/getUserPermission').then((data) => {
        this.$store.commit('permission/SET_READY');
        // this.$store.commit('auth/saveAuthInLocalStorage', data)
        this.$store.commit('auth/SET_USER', data.user);
      });
    });
    this.fetchLogo();
  },
  methods: {
    ...mapActions(['fetchLogo']),
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.login-form {
  .el-input__prefix {
    left: 0;
    width: 32px;
    text-align: center;
  }
}

$light_gray: #606266;
.login-container {
  background-image: url('./../../static/img/bg.png');
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, .1);
    background-color: #fff;
    padding: 60px 20px 20px 20px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin: auto;
  }

  .title-container {
    position: relative;
    text-align: center;
    margin-bottom: 20px;

    img {
      padding: 20px;
    }

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto;
      text-align: center;
      font-weight: bold;
    }
  }
}

.login-container--header {
  position: absolute;
  top: -20px;
  left: 5%;
  width: 90%;
  border-radius: 5px;
  background-color: #3A91EB;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, .1);

  .login-lang-select {
    font-size: 25px;
    position: absolute;
    right: 10px;
  }
}

.login-container--link {
  color: $light_gray;
}

.auth--buttonPrimary {
  outline: none;
  color: #fff;
  background-color: #3A91EB;
  border-color: #3A91EB;

  &:hover {
    color: #fff;
    background-color: #3A91EB;
    border-color: #3A91EB;
  }
}
</style>
